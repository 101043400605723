import { Component, OnInit, OnDestroy } from '@angular/core';
import { PagamentoService } from '../../shared/services/pagamento.service';
import { LoginService } from '../../shared/services/login.service';
import {pluck, takeUntil} from 'rxjs/operators';
import { Subject, of, Observable} from 'rxjs';
import { Context } from './dados-pagamento';
import {SweetalertService} from '../../shared/services/sweetalert.service';
import * as moment from 'moment/moment';
import { ActivatedRoute , Router} from '@angular/router';
import { TreinoService } from "../../shared/services/treino.service";

@Component({
  selector: 'app-dados-pagamento',
  templateUrl: './dados-pagamento.component.html',
  styleUrls: ['./dados-pagamento.component.scss'],
})
export class DadosPagamentoComponent implements OnInit, OnDestroy {

  public recebimentos: any[] = [];
  public contasReceber: any[] = [];
  public carregandoContasReceber$: any;
  public carregandoRecebimentos$: any;
  public carregandoAssinaturaPessoa$: any;
  public unsubscriber = new Subject();
  public Context = Context;
  public minhasAssinaturas: any;
  public assinaturas: any[];
  public assinaturasAtuais: any[] = [];
  public userId: any;
  public vendasPaguela: any[] = [];
  public hoje = Date.now();
  public situacaoPaguela: number;
  public tenantId: string;
  public modalShow = false;
  public modalShowAlert = false;
  public contaReceberContextoAtual: any;
  public contasPodemReceberPaguela = [];
  public amanha = moment(new Date()).add(1, 'd');
  public academiaPossuiPix = false;
  public transacoesPermitidas = false;
  public Enderecos = [];

  constructor( private pagamentoService: PagamentoService,
               private treinoService: TreinoService,
               private loginService: LoginService,
               private sweetAlert: SweetalertService,
               private router: Router
  ) {
    this.userId = this.loginService.getUserDataLocal().UserTenantId;
    this.tenantId = this.loginService.getUserDataLocal().TenantId;
  }

  public ngOnInit(): void {
    this.verificarSeAcademiaLiberouTransacoes();
    this.obterSituacaoPaguela();
    this.verificarSeAcademiaPossuiPix();
    this.obterRecebimentos();
    this.obterContasAReceber();
    this.obterAssinaturaPessoa();
    this.obterEndereco();
  }

  public ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  obterEndereco(): void{
    this.pagamentoService.getCliente(this.userId).pipe(takeUntil(this.unsubscriber)).subscribe((data) => {
      this.Enderecos = data['Enderecos'];
    })
  }

  public obterRecebimentos(): void {
    this.pagamentoService.obterRecebimentosPessoaId(this.userId).pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
      this.recebimentos = this.sort(data, 'DataCadastro');
      this.carregandoRecebimentos$ = of(true);
    }, (error) => {
      this.carregandoRecebimentos$ = of(true);
    });
  }

  public obterSituacaoPaguela() {
    this.pagamentoService.obterContaIdPaguelaPorPessoaId(this.tenantId).pipe(takeUntil(this.unsubscriber)).subscribe((result: string) => {
      this.pagamentoService.obterSituacaoPaguela(result).pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
        this.situacaoPaguela = data.Situacao;
        this.carregandoContasReceber$ = of(true);
      }, () => this.carregandoContasReceber$ = of(true));
    },() => this.carregandoContasReceber$ = of(true));
  }

  public permitirPagarComPaguela(contaReceber, proveniente) {
    const obj = {
      venda: this.contasReceber.find(item => item.TipoProveniente === 'VENDA').ContaReceberId,
      assinatura: this.contasReceber.find(item => item.TipoProveniente === 'ASSINATURA').ContaReceberId,
      servico: this.contasReceber.find(item => item.TipoProveniente === 'SERVICO').ContaReceberId
    };
    return obj[`${proveniente.toLowerCase()}`] === contaReceber;
  }

  public obterContasAReceber(): void {
    this.pagamentoService.obterContasPagarPorPessoaId(this.userId).pipe(takeUntil(this.unsubscriber)).subscribe((contasPagar: any) => {
      contasPagar.forEach((data) => {
        if (data.VrOriginal !== data.VrTotalRecebido) {
          this.contasReceber.push(data);
        }
      });
    }, (error) => {
      this.carregandoContasReceber$ = of(true);
    });
  }

  public sort(obj, campoComparacao) {
    return obj.sort((a, b) => {
      if (moment(a[campoComparacao]).isAfter(b[campoComparacao])){
        return 1;
      }

      if (moment(a[campoComparacao]).isBefore(b[campoComparacao])){
        return -1;
      }

      return 0;
    });
  }

  public filtrarProximoContasAPagarPorIdEProveniente(proveniente) {
    const provenient = proveniente.toLowerCase();
    const comparadores = {
      assinatura: 'AssinaturaPessoaId',
      venda: 'VendaProdutoId',
      servico: 'ServicoPessoaId'
    };
    const setIdsDiferentes = new Set();
    const arrContaReceberIdsDiferentes = [];
    this.contasReceber.map(cr => cr[comparadores[provenient]]).forEach(cr => setIdsDiferentes.add(cr));
    setIdsDiferentes.forEach(assinaturaPessoaId => {
      if (assinaturaPessoaId) {
        arrContaReceberIdsDiferentes
          .push(this.filtrarContaPagarPorProveniente(proveniente, comparadores[provenient], assinaturaPessoaId));
      }});
    return arrContaReceberIdsDiferentes;
  }

  public proximasCobrancas() {
    const contasAssinatura = this.filtrarProximoContasAPagarPorIdEProveniente('ASSINATURA');
    const contasVenda = this.filtrarProximoContasAPagarPorIdEProveniente('VENDA');
    const contasServico = this.filtrarProximoContasAPagarPorIdEProveniente('SERVICO');
    const arrAux = [];

    if (contasAssinatura) {
      contasAssinatura.forEach((item) => arrAux.push(item));
    }
    if (contasVenda) {
      contasVenda.forEach((item) => arrAux.push(item));
    }
    if (contasServico) {
      contasServico.forEach((item) => arrAux.push(item));
    }
    return this.sort(arrAux, 'DataVencimento');
  }

  public filtrarContaPagarPorProveniente(proveniente, comparador, comparadorId) {
    if(!proveniente || !comparador || !comparadorId) {return;}
    return this.sort(this.contasReceber.filter(cr => cr.TipoProveniente === proveniente && cr[comparador] === comparadorId),
      'DataVencimento')[0];
  }

  public obterAssinaturaPessoa(): void {
    this.pagamentoService.obterAssinaturaPorPessoaId().pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
      this.minhasAssinaturas = data;
      this.verificarAssinaturaValida();
      this.carregandoAssinaturaPessoa$ = of(true);
    }, (error) => {
      this.carregandoAssinaturaPessoa$ = of(true);
    });
  }

  verificarAssinaturaValida() {
    this.minhasAssinaturas.forEach((assinatura) => {
      if (moment(assinatura.DataFim).isAfter(this.hoje) && !assinatura.Encerrado && !assinatura.Finalizado) {
        this.assinaturasAtuais.push(assinatura);
        if (assinatura?.VendaPagueLaId) {
          this.obterVendaPagueLa(assinatura.VendaPagueLaId, assinatura.AssinaturaPessoaId);
        }
      }
    });
  }

  obterVendaPagueLa(vendaPagualaId, assinaturaPessoaId) {
    this.pagamentoService.obterVendaPagueLa(vendaPagualaId).pipe(takeUntil(this.unsubscriber),
      pluck('Cartao'))
      .subscribe((data) => this.vendasPaguela[assinaturaPessoaId] = data);
  }

  public hasContasReceber(proveniente?: string): boolean {
    if(!this.contasReceber) {return false;}
    const _proveniente = `${proveniente.toUpperCase()}`;

    for (const cr of this.contasReceber) {
      if ( cr.VrTotalRecebido === 0 && cr.VrOriginal !== cr.VrTotalRecebido &&
        cr.VrAbertoAtual > 0 && !(cr.VrTotalRecebido >= cr.VrOriginal) && cr.TipoProveniente === _proveniente) {
        return true;
      }
      if (cr.VrOriginal !== cr.VrTotalRecebido && cr.VrAbertoAtual > 0) {
        return true;
      }
    }

    return false;
  }

  vincularCartao(assinatura) {
    this.sweetAlert.loading('Pagamento com Cartão de Crédito');
    this.pagamentoService.vincularCartao(assinatura.PessoaId, assinatura.AssinaturaPessoaId)
      .pipe(takeUntil(this.unsubscriber)).subscribe((data) => {
      this.sweetAlert.close();
      this.router.navigate(['/painel/dados-pagamento/pagar/', data.TokenId]);
    });
  }

  criarVenda(contasReceber, recorrente, pix) {
    this.abrirFormasPagamento();
    const receber = {
      PessoaId: this.userId,
      Recorrente: recorrente,
      ContasReceber: contasReceber,
      PessoaAgrupadoraId: this.userId,
      DataVencimento: this.amanha,
    };
    const criarVenda = pix ? this.pagamentoService.criarVendaPix(receber) : this.pagamentoService.criarVendaCartao(receber);

    this.sweetAlert.loading(`${pix ? 'Pagamento com Pix' : 'Pagamento com Cartão de Crédito'}`);
    this.pagamentoService.criarVendaCartao(receber);
      criarVenda.pipe(takeUntil(this.unsubscriber)).subscribe((data) => {
      this.sweetAlert.close();
      this.router.navigate([`/painel/dados-pagamento/pagar/${pix ? 'pix/' : ''}`, data.TokenId]);
    });
  }

  abrirFormasPagamento(contaReceber?) {
    if(this.Enderecos.length){
      if (!contaReceber) {return this.modalShow = false;}
      this.contaReceberContextoAtual = [contaReceber];
      if (!this.academiaPossuiPix) {
        this.modalShow = false;
        this.criarVenda([contaReceber], false, false);
      }
      this.modalShow = !this.modalShow;
    } else {
      this.modalShowAlert = true;
    }
  }

  public contaReceberAgendado(contaReceber): boolean {
    const signature = this.assinaturasAtuais.find(assinatura => contaReceber.AssinaturaPessoaId === assinatura.AssinaturaPessoaId);
    return signature && signature.VendaPagueLaId;
  }

  existeRecebimento(proveniente) {
    return !!this.recebimentos.find(r => r.TipoProveniente === proveniente.toUpperCase());
  }

  verificarSeAcademiaPossuiPix() {
    this.pagamentoService.usuarioPossuiPix(this.tenantId).pipe(takeUntil(this.unsubscriber)).subscribe((data: boolean) => {
      this.academiaPossuiPix = data;
    });
  }

  verificarSeAcademiaLiberouTransacoes() {
    this.treinoService.obterAjustes().pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
      this.transacoesPermitidas = data.PermitirTransacoesPaguela;
    });
  }

}
