import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { TranslateAdapter } from '../helpers/translateAdapter';
import { ETipoCadastroPessoa, ETipoProveniente, pagamentosDictionary } from '../dictionaries/pagamento.dictionary';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PagamentoService {

  private pagamentosTranslate = new TranslateAdapter(pagamentosDictionary);

  constructor( private http: HttpClient) { }

  getCliente(id: string): Observable <{}> {
    return this.http
      .get(`${API.base}/Pessoas/ObterPorId?id=${id}`, {
        withCredentials: true,
      });
  }

  obterRecebimentosPessoaId(pessoaId) {
    return this.http.get(`${API.receivable_service}/Receivement/GetReceivementByPersonId?personId=${pessoaId}`, {
      withCredentials: true,
    }).pipe(map((e: any) => {
      if (!e) return null;
      return e.map(element => {
        const elementTranslated: any = this.pagamentosTranslate.translate(element);
        elementTranslated.TipoCadastro = ETipoCadastroPessoa[elementTranslated.TipoCadastro];
        elementTranslated.Situacao = elementTranslated.Situacao ? 'A' : 'I';
        elementTranslated.TipoProveniente = ETipoProveniente[elementTranslated.TipoProveniente];
        return elementTranslated;
      });
    }));
  }

  obterAssinaturaPorPessoaId() {
    return this.http.get(`${API.base}/Assinaturas/ObterAssinaturaPessoaPorPessoaIdTreino`, {
      withCredentials: true
    });
  }
// eslint-disable-next-line @typescript-eslint/naming-convention
  vincularCartao(pessoaId: string, assinaturaPessoaId: string, incluirAVencer = false): Observable<any> {
    return this.http

      .post(`${API.base}/Faturamento/VincularCartao?IncluirAVencer=${incluirAVencer}`, { pessoaId, assinaturaPessoaId } , {
        withCredentials: false
      });
  }

  criarVendaCartao(contasAReceber): Observable < any > {
    return this.http
      .post(`${API.base}/PagueLa/CriarVendaCartao`, contasAReceber, {
        withCredentials: true
      });
  }

  criarVendaPix(contasAReceber): Observable < any > {
    return this.http
      .post(`${API.base}/PagueLa/CriarVendaPix`, contasAReceber, {
        withCredentials: true
      });
  }

  usuarioPossuiPix(pessoaId) {
    return this.http.get(`${API.base}/paguela/PossuiServicoPixAtivo?pessoaId=${pessoaId}`,{
      withCredentials: true
    });
  }

  obterContasPagarPorPessoaId(pessoaId ) {
    return this.http.get(`${API.receivable_service}/AccountReceivable/GetAccountsReceivablesDetailedByPersonId?personId=${pessoaId}`, {
      withCredentials: true
    }).pipe(map((e: any) => {
      if (!e) return null;
      return e.map(element => {
        const elementTranslated: any = this.pagamentosTranslate.translate(element);
        elementTranslated.TipoCadastro = ETipoCadastroPessoa[elementTranslated.TipoCadastro];
        elementTranslated.Situacao = elementTranslated.Situacao ? 'A' : 'I';
        elementTranslated.TipoProveniente = ETipoProveniente[elementTranslated.TipoProveniente];
        return elementTranslated;
      });
    }));
  }

  obterVendaPagueLa(vendaPaguelaId) {
    return this.http.get( `${API.paguela}/Vendas/ObterPorId?id=${vendaPaguelaId}`, {
      withCredentials: false
    });
  }

  obterSituacaoPaguela(contaId) {
    return this.http.get(`${API.paguela}/Contas/ObterSituacao?id=${contaId}`, {
      withCredentials: false
    });
  }

  obterContaIdPaguelaPorPessoaId(pessoaId) {
    return this.http.get(`${API.base}/Pessoas/ObterPagueLaPessoaIdPorPessoaId?pessoaId=${pessoaId}`, {
      withCredentials: false,
    });
  }

};
